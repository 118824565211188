@font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato-Regular.woff2') format('woff2'),
        url('./fonts/Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato-Light.woff2') format('woff2'),
        url('./fonts/Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato-Bold.woff2') format('woff2'),
        url('./fonts/Lato-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('./#{$icomoon-font-path}/#{$icomoon-font-family}.eot?swzml7');
  src:  url('./#{$icomoon-font-path}/#{$icomoon-font-family}.eot?swzml7#iefix') format('embedded-opentype'),
    url('./#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?swzml7') format('truetype'),
    url('./#{$icomoon-font-path}/#{$icomoon-font-family}.woff?swzml7') format('woff'),
    url('./#{$icomoon-font-path}/#{$icomoon-font-family}.svg?swzml7##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* Custom Icons */
.icon-accessibility {
  &:before {
    content: $icon-accessibility; 
  }
}
.icon-med {
  &:before {
    content: $icon-med; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-microscope {
  &:before {
    content: $icon-microscope; 
  }
}
.icon-scop {
  &:before {
    content: $icon-scop; 
  }
}
.icon-tube {
  &:before {
    content: $icon-tube; 
  }
}