/// Margin of a message
/// @group message
$messageMargin: 1rem 0;

/// Padding of a message
/// @group message
$messagePadding: 1rem 1.5rem;

/// Border width of a message
/// @group message
$messageBorderWidth: 0 0 0 6px;

/// Font size of a message icon
/// @group message
$messageIconFontSize: 1.5rem;

/// Font size of a message text
/// @group message
$messageTextFontSize: 1rem;

/// Font weight of a message text
/// @group message
$messageTextFontWeight: 500;

/// Padding of an inline message
/// @group message
$inlineMessagePadding: $inputPadding;

/// Margin of an inline message
/// @group message
$inlineMessageMargin: 0;

/// Font size of an inline message icon
/// @group message
$inlineMessageIconFontSize: 1rem;

/// Padding of an inline message text
/// @group message
$inlineMessageTextFontSize: 1rem;

/// Border width of an inline message text
/// @group message
$inlineMessageBorderWidth: 1px;

/// Font size of a toast message icon
/// @group message
$toastIconFontSize: 2rem;

/// Margin of a toast message text
/// @group message
$toastMessageTextMargin: 0 0 0 1rem;

/// Margin of a toast message
/// @group message
$toastMargin: 0 0 1rem 0;

/// Padding of a toast message
/// @group message
$toastPadding: 1rem;

/// Border width of a toast message
/// @group message
$toastBorderWidth: 0 0 0 6px;

/// Box shadow of a toast message
/// @group message
$toastShadow: 0 3px 14px 0 rgba(0, 0, 0, 0.3);

/// Opacity of a toast message
/// @group message
$toastOpacity: .9;

/// Font weight of a toast message title text
/// @group message
$toastTitleFontWeight: 700;

/// Margin of a toast message detail text
/// @group message
$toastDetailMargin: $inlineSpacing 0 0 0;

/// Background of an info message
/// @group message
$infoMessageBg: rgba(219, 234, 254, 0.7) !default;

/// Border of an info message
/// @group message
$infoMessageBorder: solid #3b82f6 !default;

/// Text color of an info message
/// @group message
$infoMessageTextColor: #3b82f6 !default;

/// Icon color of an info message
/// @group message
$infoMessageIconColor: #3b82f6 !default;

/// Background of a success message
/// @group message
$successMessageBg: rgba(228, 248, 240, 0.7) !default;

/// Border of a success message
/// @group message
$successMessageBorder: solid #1ea97c !default;

/// Text color of a success message
/// @group message
$successMessageTextColor: #1ea97c !default;

/// Icon color of a success message
/// @group message
$successMessageIconColor: #1ea97c !default;

/// Background of a warning message
/// @group message
$warningMessageBg: rgba(255, 242, 226, 0.7) !default;

/// Border of a warning message
/// @group message
$warningMessageBorder: solid #cc8925 !default;

/// Text color of a warning message
/// @group message
$warningMessageTextColor: #cc8925 !default;

/// Icon color of a warning message
/// @group message
$warningMessageIconColor: #cc8925 !default;

/// Background of an error message
/// @group message
$errorMessageBg: rgba(255, 231, 230, 0.7) !default;

/// Border of an error message
/// @group message
$errorMessageBorder: solid #ff5757;

/// Text color of an error message
/// @group message
$errorMessageTextColor: #ff5757 !default;

/// Icon color of an error message
/// @group message
$errorMessageIconColor: #ff5757 !default;

/// Background of a contrast message
/// @group message
$contrastMessageBg: #fff;

/// Border of a contrast message
/// @group message
$contrastMessageBorder: solid #000;

/// Text color a contrast message
/// @group message
$contrastMessageTextColor: #000;

/// Icon color of a contrast message
/// @group message
$contrastMessageIconColor: $contrastMessageTextColor;
