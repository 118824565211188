@layer normalize, primeng;
@import "normalize.css" layer(normalize-reset);
@import "assets/flags/freakflags.css";
/* @import "primeng/resources/themes/lara-light-blue/theme.css"; */
/* @import "primeflex/themes/primeone-light.css"; */
@import "assets/themes/medis-theme/theme.scss";
@import "primeicons/primeicons.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

.justify-content-end {
  justify-content: end;
}

html {
  // main layout (in app.component) assumes it handles overflow itself,
  // so scrollbars atop it mustn't exist
  overflow: hidden;
  font-size: 14px;
}

body {
  font-family: var(--font-family);
}

.center-vertical {
  position: relative;
  z-index: 15;
  top: 0;
  left: 0;
  display: table;
  width: 100%;
  height: 100%;
}

.content-box-header {
  position: relative;
  border-width: 1px;
  border-style: solid;
}

.content-box-header {
  font-size: 14px;
  text-transform: uppercase;
  margin: -1px -1px 0;
  padding: 15px;
  border-color: transparent;
}

.badge-purple {
  color: #fff;
  background: #984dff;
  border-color: #7a3ecc;
}

.badge-yellow {
  color: #fff;
  background: #fc0;
  border-color: #deb200;
}

.badge-warning {
  color: #fff;
  border-color: #d67520;
  background: #e67e22;
}

.badge-info {
  color: #fff;
  background: #5bc0de;
  border-color: #31b0d5;
}

.badge-azure {
  color: #fff;
  background: #41e5c0;
  border-color: #3acead;
}

.badge-success {
  color: #fff;
  background: #5cb85c;
  border-color: #4a9f4a;
}

.badge-secondary {
  color: #fff;
  background: #868e96;
}

.badge-pink {
  color: #fff;
  background: #ffafcd;
  border-color: #ff8fb4;
}

.badge-default {
  background: #636c72;
}

.badge-danger {
  color: #fff;
  border-color: #cf4436;
  background: #e74c3c;
}

.float-right {
  float: right;
}
