// Theme Specific Variables
$primaryColor: #4B6CB7;
$primaryDarkColor: #405C9B;
$primaryDarkerColor: #182848;
$primaryTextColor: #ffffff;
$highlightColor: #1d4ed8;

$colors: (
    "blue": #3B82F6,
    "green": #22c55e,
    "yellow": #eab308,
    "cyan": #06b6d4,
    "pink": #E91E63,
    "indigo": #6366f1,
    "teal": #14b8a6,
    "orange": #f97316,
    "bluegray": #64748b,
    "purple": #a855f7,
    "red": #ff3d32,
    "primary": $primaryColor,
    "primarydark": $primaryDarkColor,
);

$shade000: #ffffff !default; //surface
$shade100: #f9fafb !default; //header background
$shade200: #f3f4f6 !default; //hover background
$shade300: #e5e7eb !default; //border, divider
$shade400: #d1d5db !default; //input border
$shade500: #9ca3af !default; //input icon
$shade600: #6b7280 !default; //text secondary color
$shade700: #4b5563 !default; //text color
$shade800: #374151 !default; //unused
$shade900: #1f2937 !default; //unused

// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/panel';
@import './variables/_data';
@import './variables/_overlay';
@import './variables/_message';
@import './variables/_menu';
@import './variables/_media';
@import './variables/_misc';
@import './variables/_icons';

:root {
    font-family: Lato, sans-serif;
    --font-family: Lato, sans-serif;
    --surface-a:#ffffff;
    --surface-b: #{$shade100};
    --surface-c:#e9ecef;
    --surface-d:#dee2e6;
    --surface-e:#ffffff;
    --surface-f:#ffffff;
    --text-color:#ffffff;
    --text-color-secondary:#6B7280;
    --primary-color: #{$primaryColor};
    --primary-dark-color: #{$primaryDarkColor};
    --primary-color-text:#ffffff;
    --surface-0: #ffffff;
    --surface-50: #FAFAFA;
    --surface-100: #{$shade100};
    --surface-200: #EEEEEE;
    --surface-300: #{$shade300};
    --surface-400: #{$shade400};
    --surface-500: #9E9E9E;
    --surface-600: #757575;
    --surface-700: #{$shade700};
    --surface-800: #{$shade800};
    --surface-900: #{$shade900};
    --content-padding:#{$panelContentPadding};
    --inline-spacing:#{$inlineSpacing};
    --border-radius:#{$borderRadius};
    --surface-ground:#EFF3F8;
    --surface-section:#ffffff;
    --surface-card:#ffffff;
    --surface-overlay:#ffffff;
    --surface-border:#DFE7EF;
    --surface-hover:#e9ecef;
    --maskbg: #{$maskBg};
    --highlight-bg: #{$highlightBg};
    --highlight-text-color: #{$highlightTextColor};
    --focus-ring: #{$focusShadow};
    color-scheme: light;
}
