/* Customizations to the designer theme should be defined here */

// Custom Margins
@layer primeng {
  @mixin primeng-tag-style(
    $bg-color-class,
    $text-color-class,
    $border-color-class
  ) {
    background-color: var(--#{$bg-color-class});
    color: var(--#{$text-color-class});
    border: 1px solid var(--#{$border-color-class});
  }

  .tag {
    text-transform: uppercase;
    font-size: 1rem;
    &--small {
      @include styleclass("text-sm font-bold py-1");
    }
    &--large {
      @include styleclass("text-lg font-bold py-2");
    }
    .p-tag-value {
      line-height: 1em;
    }
    &--green {
      @include primeng-tag-style("green-100", "green-700", "green-700");
    }
    &--blue {
      @include primeng-tag-style("blue-100", "blue-700", "blue-700");
    }
    &--red {
      @include primeng-tag-style("red-100", "red-700", "red-700");
    }
    &--orange {
      @include primeng-tag-style("orange-100", "orange-700", "orange-700");
    }
    &--purple {
      @include primeng-tag-style("purple-100", "purple-700", "purple-700");
    }
    &--pink {
      @include primeng-tag-style("pink-100", "pink-700", "pink-700");
    }
    &--teal {
      @include primeng-tag-style("teal-100", "teal-700", "teal-700");
    }
    &--cyan {
      @include primeng-tag-style("cyan-100", "cyan-700", "cyan-700");
    }
    &--yellow {
      @include primeng-tag-style("yellow-100", "yellow-700", "yellow-700");
    }
    &--default {
      @include primeng-tag-style("surface-100", "surface-700", "surface-700");
    }
  }

  // Form Validation
  .form {
    &-group {
      position: relative;
      .p-error {
        padding-top: 2px;
        position: absolute;
        bottom: 0;
        transform: translateY(100%);
      }
      .p-icon {
        z-index: 1;
      }
    }
    &-section {
      display: flex;
      flex-direction: column;
      background: $shade000;
      border-radius: $borderRadius;
      padding: 1.25rem;
      gap: 1.75rem;
      @include styleclass("border-1 surface-border");
      &__title {
        @include styleclass("text-xl text-color-secondary m-0");
      }
    }
  }

  .p-datatable {
    // remove border from last trs' tds to avoid duplicating border (since table itself has a border as well)
    tr:last-child td {
      border-bottom: 0;
    }
    .p-sortable-column {
      // To ensure p-sortIcon is always to the side of header label
      // we must wrap th contents into some container, a div in this case
      > div {
        display: flex;
        align-items: center;
      }
    }

    // Table TH uppercase
    .p-datatable-thead {
      th {
        text-transform: uppercase;

        &.p-sortable-column {
          .p-icon-wrapper {
            display: inline-flex;
            position: relative;
            top: 2px;
          }

          .p-icon {
            color: $shade800;
          }
        }
      }
    }
  }


  // More Menu Dropdown Alternate Style
  .more-menu {
    $menuitemTextColor: $shade700;
    $menuitemIconColor: $shade600;
    $menuitemTextActiveColor: $highlightTextColor;
    $menuitemHoverBg: $shade200;
    $menuitemTextHoverColor: $shade700;
    $menuitemIconHoverColor: $shade600;
    $highlightTextColor: $highlightColor;
    $menuitemActiveBg: $highlightBg;
    $menuitemTextFocusColor: $highlightColor;
    .p-menuitem {
      > .p-menuitem-content {
        color: $menuitemTextColor;
        transition: $listItemTransition;
        border-radius: $menuitemBorderRadius;

        .p-menuitem-link {
          color: $menuitemTextColor;
          padding: $menuitemPadding;
          user-select: none;

          .p-menuitem-text {
            color: $menuitemTextColor;
          }

          .p-menuitem-icon {
            color: $menuitemIconColor;
            margin-right: $inlineSpacing;
          }

          .p-submenu-icon {
            color: $menuitemIconColor;
          }
        }
      }

      &.p-highlight {
        > .p-menuitem-content {
          color: $menuitemTextActiveColor;
          background: $menuitemActiveBg;

          .p-menuitem-link {
            .p-menuitem-text {
              color: $menuitemTextActiveColor;
            }

            .p-menuitem-icon,
            .p-submenu-icon {
              color: $menuitemIconActiveColor;
            }
          }
        }

        &.p-focus {
          > .p-menuitem-content {
            background: $menuitemActiveFocusBg;
          }
        }
      }

      &:not(.p-highlight):not(.p-disabled) {
        &.p-focus {
          > .p-menuitem-content {
            color: $menuitemTextFocusColor;
            background: $menuitemFocusBg;

            .p-menuitem-link {
              .p-menuitem-text {
                color: $menuitemTextFocusColor;
              }

              .p-menuitem-icon,
              .p-submenu-icon {
                color: $menuitemIconFocusColor;
              }
            }
          }
        }

        > .p-menuitem-content {
          &:hover {
            color: $menuitemTextHoverColor;
            background: $menuitemHoverBg;

            .p-menuitem-link {
              .p-menuitem-text {
                color: $menuitemTextHoverColor;
              }

              .p-menuitem-icon,
              .p-submenu-icon {
                color: $menuitemIconHoverColor;
              }
            }
          }
        }
      }
    }
  }
  // Border radius for table as modifier paginator style
  .p-datatable {
    &-wrapper {
      border: $tableHeaderCellBorder;
      border-radius: $borderRadius;
      overflow: hidden;
    }
    &:has(.p-paginator-bottom) {
      .p-datatable-wrapper {
        margin-bottom: 0.5rem;
      }
    }
  }

  .row {
    &--highlight {
      > td {
        background: var(--green-50);
      }
    }
    &--highlight-free {
      > td {
        background: var(--primary-50);
      }
    }
  }

  .header-div{
    &--highlight {
        background: var(--primary-50);
    }
  }

  .date-time{
    &--highlight {
      color: map-get($colors, "red") !important;
    }
  }

  .page {
    &__header {
      border-color: $shade300;
    }
    &__title {
      font-weight: 600;
      color: $shade700;
    }
  }

  .dept-menu {
    &__item {
      transition: $transitionDuration;
      cursor: pointer;
      &--active {
        background: $highlightBg;
      }
      &--active &-proffesion,
      &--active &-department {
        color: $primaryColor;
      }
      &:hover {
        background: $shade200;
      }
      &:hover &-profession,
      &:hover &-department {
        color: $shade700;
      }
    }
    &__btn {
      .p-button-icon {
        transition: $transitionDuration;
      }
      &--active {
        .p-button-icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  .auth-dept-menu {
    &__item {
      background: $shade200;
      &-profession,
      &-department {
        color: $shade700;
      }
      &:hover {
        background: $highlightBg;
      }
      &:hover &-profession,
      &:hover &-department {
        color: $primaryColor;
      }
    }
  }

  .container {
    width: 1024px;
    @include styleclass("px-4");
    &--full {
      width: 100%;
    }
  }

  .reservation {
    .p-tabview {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      @include styleclass("pb-4");
      .p-tabview-nav-content {
        border-radius: 6px;
      }
      .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        &--left {
          left: -16px;
        }
        &--right {
          right: -16px;
        }
        .p-button {
          width: 32px;
          height: 40px;
          background: var(--surface-0);
          border: 1px solid $shade300;
          &:hover {
            background: var(--surface-0);
            border-color: var(--primary-color);
            color: var(--primary-color);
          }
        }
      }
      .displaced-arrow{
        right: 16.5%;
      }
    }
  }

  //remove arrow from overlay
  .p-overlaypanel:after,
  .p-overlaypanel:before {
    content: none !important;
  }

  .spinner .p-progress-spinner-circle {
    color: var(--primary-color);
    animation: spin 4s ease-in-out infinite;
  }

  @keyframes spin {
    100%,
    0% {
      stroke: var(--primary-color);
    }
    40% {
      stroke: var(--primary-color);
    }
    66% {
      stroke: var(--primary-color);
    }
    80%,
    90% {
      stroke: var(--primary-color);
    }
  }

  // Make every individual item wrap its' content when it doesn't fit
  .p-dropdown-panel {
    .p-dropdown-items {
      .p-dropdown-item {
        white-space: normal;
        word-break: break-word;
      }
    }
  }

  .p-dropdown {

    // Since dropdown size is defined with paddings :( and dropdown itself does not possess
    // defined size, having p-badge that is of higher height than font may break our layout,
    // so we set it's size to our font size explicitly... and offset it with -margin..
    // p-badge is a host to actual badge, so it's contents should still display as expected.
    p-badge {
      height: 1rem !important;
      margin-top: -6px !important;
    }

    // Hide selected item overflow with ellipsis
    .p-dropdown-label {
      * {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  // .p-hidden-accessible elements are 'absolute', but for some reason, in some cases,
  // screw the flow of the document, positioning them to top:0 seems to fix the issue
  .p-hidden-accessible {
    top: 0;
  }

  // Since we use floatLabel, various contents may get overflown by dialog header
  // To accommodate, we move bottom padding of header to top padding of content
  .p-dynamic-dialog {
    .p-dialog-header {
      padding-bottom: 0;
    }
    .p-dialog-content {
      padding-top: 1.5rem;
    }
  }
}

.p-picklist {
  display: flex;
  @include styleclass("gap-3-5");
  .p-picklist-header {
    background: var(--surface-100);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: 1px solid var(--surface-300);
  }
  .p-picklist-filter-container {
    border: 0;
  }
  &.p-picklist-striped {
    .p-picklist-list {
      .p-picklist-item:nth-child(even) {
        background: var(--bluegray-50);
      }
    }
  }
  .p-picklist-list {
    .p-picklist-item {
      &.p-highlight {
        color: $primaryColor;
        background: var(--blue-50)!important;
      }
    }
  }
  &.p-picklist-hide-move-to {
    .p-picklist-buttons.p-picklist-transfer-buttons {
      button[data-pc-section|="moveToTargetButton"],
      button[data-pc-section|="moveToSourceButton"] {
        display: none !important;
      }
    }
  }
}

.p-breadcrumb {
  border-radius: 0;
  .p-menuitem-text {
    line-height: initial;
  }
}

.patient-code {
  &__value {
    background-color: var(--bluegray-50);
    border-radius: 4px;
    padding: 3px 4px;
    border: 0.50px solid var(--bluegray-100);
  }
  &__value:active {
    background-color: var(--bluegray-100);
  }
}

.order-filter {
  background-color: var(--surface-ground);
  &__time {
    max-width: 225px;
  }
  &__date {
    max-width: 260px;
  }
  &__weekday {
    &-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.selected-row {
  background-color: #e1f1f6;
}

.order-reservation-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;

  &--left {
    left: -16px;
  }

  &--right {
    right: -16px;
  }

  .p-button {
    width: 32px;
    height: 40px;
    background: var(--surface-0);
    border: 1px solid $shade300;
    &:hover {
      background: var(--surface-0);
      border-color: var(--primary-color);
      color: var(--primary-color);
    }
  }
}

.displaced-arrow{
  right: 16.5%;
}

.calendar {
  --fc-border-color: var(--surface-300);
  .fc {
    &-toolbar-title {
      // Ensure calendar title starts with capital
      &::first-letter {
        text-transform: uppercase;
      }
    }

    &-timegrid,
    &-daygrid {
      background: var(--surface-0);
    }
    &-col-header-cell {
      background: var(--surface-100);
    }
    a.fc-event {
      white-space: normal;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: .25rem;
      b {
        @include styleclass("text-base font-normal");
      }
      i {
        @include styleclass("text-sm font-semibold");
        font-style: normal;
      }
    }
    .fc-button {
      border-radius: $borderRadius;
    }
    .fc-button-primary {
      &:not(:disabled) {
        background: var(--surface-0);
        color: var(--surface-700);
        border-color: var(--surface-400);
        &.fc-button-active,
        &:active {
          color: var(--primary-color-text);
          background: var(--primary-color);
          border-color: var(--primary-color);

          &:hover {
            background: var(--primary-600);
            border-color: var(--primary-600);
            color: var(--primary-color-text);
          }

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
    .fc-toolbar-chunk {
      display: flex;
    }
    .fc-today-button,
    .fc-prev-button,
    .fc-next-button {
      @extend .fc-button-active;
      display: flex;
      align-items: center;
    }
  }
}

.cursor-forbidden {
  cursor: not-allowed;
}

.btn-disabled {
  pointer-events: auto
}

.p-divider .p-divider-content{
  background-color: var(--surface-ground);
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  z-index: 1000;
}

.order-reservation-modal .p-dialog-header {
  background-color: var(--surface-ground);
}

.order-reservation-modal .p-dialog-content {
  background-color: var(--surface-ground);
  padding-bottom: 8rem;
}

.background-modal .p-dialog-header {
  background-color: var(--surface-ground) !important;
}

.background-modal .p-dialog-content {
  background-color: var(--surface-ground) !important;
}
