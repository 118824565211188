// Custom space scales (add your new values here)
$custom-space-scales: (
    '1-5': 0.25,
    '2-5': 0.75,
    '3-5': 1.25,
    '4-5': 1.75,
);

// Gap properties
$gap-properties: (
    'gap': 'gap',
    'row-gap': 'row-gap',
    'column-gap': 'column-gap'
) !default;

// Mixin for custom spacing classes
@mixin custom-spacing-class($inPrefix, $map, $scales, $responsive: true, $auto: false, $negative: false) {
    $inPrefix: $prefix + $inPrefix;

    @each $name, $prop in $map {
        @each $scale, $value in $scales {
            .#{$inPrefix}#{$name}-#{$scale} {
                @each $propName in $prop {
                    #{$propName}: $spacer * $value !important;
                }
            }
        }

        @if ($negative) {
            @each $scale, $value in $scales {
                .-#{$inPrefix}#{$name}-#{$scale} {
                    @each $propName in $prop {
                        #{$propName}: -1 * $spacer * $value !important;
                    }
                }
            }
        }
    }

    @if ($responsive) {
        @each $key, $val in $breakpoints {
            @media screen and (min-width: #{$val}) {
                @each $name, $prop in $map {
                    @each $scale, $value in $scales {
                        .#{$key + $separator}#{$inPrefix}#{$name}-#{$scale} {
                            @each $propName in $prop {
                                #{$propName}: $spacer * $value !important;
                            }
                        }
                    }

                    @if ($negative) {
                        @each $scale, $value in $scales {
                            .#{$key + $separator}-#{$inPrefix}#{$name}-#{$scale} {
                                @each $propName in $prop {
                                    #{$propName}: -1 * $spacer * $value !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// New mixin calls for custom spacings
@include custom-spacing-class('p', $padding-properties, $custom-space-scales);
@include custom-spacing-class('m', $margin-properties, $custom-space-scales, true, true, true);
@include custom-spacing-class('', $gap-properties, $custom-space-scales);
